import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import HomeCarousel from "../components/HomeCarousel";

import "../css/Home.css";

export default function () {
  console.log("Re-rendering Home");
  return (
    <>
      <Helmet>
        <title>Home | PCI Custom Builders</title>
      </Helmet>
      <Row className="p-0" style={{ marginTop: -16 }}>
        <Col className="p-0">
          <HomeCarousel />
        </Col>
      </Row>
    </>
  );
}
