import axios from "axios";

const API_PATH =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api"
    : process.env.PUBLIC_URL + "/api";

export const ACTIONS = {
  CONTACT_SEND_REQUESTED: "CONTACT_SEND_REQUESTED",
  CONTACT_SEND_SUCCESSFUL: "CONTACT_SEND_SUCCESSFUL",
  CONTACT_SEND_FAILED: "CONTACT_SEND_FAILED",
  CONTACT_SEND_RESET: "RESET_RESPONSE",

  TAGS_REQUESTED: "TAGS_REQUESTED",
  TAGS_SUCCESSFUL: "TAGS_SUCCESSFUL",
  TAGS_FAILED: "TAGS_FAILED",

  JOBS_REQUESTED: "JOBS_REQUESTED",
  JOBS_SUCCESSFUL: "JOBS_SUCCESSFUL",
  JOBS_FAILED: "JOBS_FAILED",

  SHOWCASE_REQUESTED: "SHOWCASE_REQUESTED",
  SHOWCASE_SUCCESSFUL: "SHOWCASE_SUCCESSFUL",
  SHOWCASE_FAILED: "SHOWCASE_FAILED",

  IMAGES_REQUESTED: "IMAGES_REQUESTED",
  IMAGES_SUCCESSFUL: "IMAGES_SUCCESSFUL",
  IMAGES_FAILED: "IMAGES_FAILED",

  UPDATE_FILTER_TAG: "UPDATE_FILTER_TAG",
  UPDATE_FILTER_JOB: "UPDATE_FILTER_JOB",

  INSTAGRAM_FEED_REQUESTED: "INSTAGRAM_FEED_REQUESTED",
  INSTAGRAM_FEED_SUCCESSFUL: "INSTAGRAM_FEED_SUCCESSFUL",
  INSTAGRAM_FEED_FAILED: "INSTAGRAM_FEED_FAILED"
};

export function sendContactForm(dispatch, data, callback) {
  axios
    .post(API_PATH + "/contact_form.php", data)
    .then(() => {
      dispatch({
        type: ACTIONS.CONTACT_SEND_SUCCESSFUL
      });
      callback();
    })
    .catch(() => {
      dispatch({
        type: ACTIONS.CONTACT_SEND_FAILED
      });
    });
  dispatch({
    type: ACTIONS.CONTACT_SEND_REQUESTED
  });
}

export function resetContactResponse(dispatch) {
  dispatch({ type: ACTIONS.CONTACT_SEND_RESET });
}

export function requestTags(dispatch) {
  axios
    .get(API_PATH + "/get_tags.php")
    .then(res => {
      dispatch({
        type: ACTIONS.TAGS_SUCCESSFUL,
        tags: res.data.tags,
        tagsOrder: res.data.tags_order
      });
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.TAGS_FAILED
      });
    });
  dispatch({
    type: ACTIONS.TAGS_REQUESTED
  });
}

export function requestJobs(dispatch) {
  axios
    .get(API_PATH + "/get_jobs.php?ignore_misc=true")
    .then(res => {
      dispatch({
        type: ACTIONS.JOBS_SUCCESSFUL,
        jobs: res.data.jobs,
        jobsOrder: res.data.jobs_order
      });
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.JOBS_FAILED
      });
    });
  dispatch({
    type: ACTIONS.JOBS_REQUESTED
  });
}

export function requestShowcase(dispatch) {
  axios
    .get(API_PATH + "/get_showcase_urls.php")
    .then(res => {
      dispatch({
        type: ACTIONS.SHOWCASE_SUCCESSFUL,
        showcase: res.data.showcase
      });
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.SHOWCASE_FAILED
      });
    });
  dispatch({
    type: ACTIONS.SHOWCASE_REQUESTED
  });
}

export function requestImages(dispatch) {
  axios
    .get(API_PATH + "/get_images.php")
    .then(res => {
      dispatch({
        type: ACTIONS.IMAGES_SUCCESSFUL,
        images: res.data.images
      });
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.IMAGES_FAILED
      });
    });
  dispatch({
    type: ACTIONS.IMAGES_REQUESTED
  });
}

export function updateFilterTag(dispatch, value) {
  dispatch({
    type: ACTIONS.UPDATE_FILTER_TAG,
    value: value
  });
}

export function updateFilterJob(dispatch, value) {
  dispatch({
    type: ACTIONS.UPDATE_FILTER_JOB,
    value: value
  });
}

export function requestInstagramFeed(dispatch) {
  axios
    .get(API_PATH + "/get_instagram_feed.php")
    .then(res => {
      dispatch({
        type: ACTIONS.INSTAGRAM_FEED_SUCCESSFUL,
        instagramFeed: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.INSTAGRAM_FEED_FAILED
      });
    });
  dispatch({
    type: ACTIONS.INSTAGRAM_FEED_REQUESTED
  });
}
