import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Breadcrumb, Button } from "react-bootstrap";

import { requestJobs, requestTags, requestImages } from "../redux/actions.js";
import SpinnerContainer from "../components/SpinnerContainer.js";
import Gallery from "../components/Gallery.js";
import useIsMobile from "../globals/useIsMobile.js";

import "../css/JobTag.css";

export default function ({ type }) {
  const isMobile = useIsMobile();

  // Component state
  const [pageNum, setPageNum] = useState(0);
  const pageSize = isMobile ? 15 : 25;

  const { entryId } = useParams();

  const dispatch = useDispatch();
  const [
    jobsPending,
    jobsLoaded,
    jobs,
    tagsPending,
    tagsLoaded,
    tags,
    imagesPending,
    imagesLoaded,
    images
  ] = useSelector(state => [
    state.jobsPending,
    state.jobsLoaded,
    state.jobs,
    state.tagsPending,
    state.tagsLoaded,
    state.tags,
    state.imagesPending,
    state.imagesLoaded,
    state.images
  ]);

  const entries = type === "job" ? jobs : tags;
  const entry = entries[entryId];

  useEffect(() => {
    if (!jobsPending && !jobsLoaded) {
      requestJobs(dispatch);
    }
    if (type === "tag" && !tagsPending && !tagsLoaded) {
      requestTags(dispatch);
    }
    if (!imagesPending && !imagesLoaded) {
      requestImages(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const numPages = entry ? Math.ceil(entry.image_ids.length / pageSize) : 0;

  return (
    <>
      <Helmet>
        <title>{entry ? entry.name : "Loading"} | PCI Custom Builders</title>
      </Helmet>
      {!jobsLoaded || (type === "tag" && !tagsLoaded) || !imagesLoaded ? (
        <SpinnerContainer />
      ) : (
        <>
          <Row className="text-center justify-content-center padded-row pb-0">
            <Col md={12}>
              <h2>{entry.name}</h2>
            </Col>
          </Row>
          <Row className="padded-row pt-0">
            <Col md={12}>
              <Breadcrumb>
                <Link
                  to={type === "job" ? "/projects/" : "/portfolio/"}
                  className="breadcrumb-item"
                >
                  {type === "job" ? "Featured Projects" : "Portfolio"}
                </Link>
                <Breadcrumb.Item active>{entry.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          {numPages > 1 && (
            <Row className="padded-row pb-0">
              <Col className="d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  disabled={pageNum === 0}
                  onClick={() => setPageNum(pageNum - 1)}
                >
                  Prev
                </Button>
                <div id="page-nums" className="px-2">
                  Page {pageNum + 1} of {numPages}
                </div>
                <Button
                  variant="primary"
                  disabled={pageNum === numPages - 1}
                  onClick={() => setPageNum(pageNum + 1)}
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}
          <Row className="padded-row">
            <Col>
              {!entry.image_ids ? (
                <span>No images yet</span>
              ) : (
                <Gallery
                  images={entry.image_ids.map((id, index) => ({
                    src: images[id].image_url,
                    thumbnail: images[id].thumbnail_url,
                    key: id.toString(),
                    caption: jobs[images[id].job]
                      ? jobs[images[id].job].name
                      : "",
                    width: images[id].width,
                    height: images[id].height
                  }))}
                  onChange={index => setPageNum(Math.floor(index / pageSize))}
                  targetRowHeight={156}
                  startIndex={pageNum * pageSize}
                  endIndex={(pageNum + 1) * pageSize}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
