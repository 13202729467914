import React from "react";
import { Helmet } from "react-helmet";

import "../css/NotFound.css";

export default function(props) {
  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <div id="div-404">
        <h1>404</h1>
        <h2>Not Found</h2>
      </div>
    </>
  );
}
