import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";

import { requestJobs, requestTags, requestImages } from "../redux/actions.js";
import SpinnerContainer from "../components/SpinnerContainer.js";

import "../css/JobsTags.css";

export default function ({ type }) {
  const dispatch = useDispatch();
  const [
    jobsPending,
    jobsLoaded,
    jobs,
    jobsOrder,
    tagsPending,
    tagsLoaded,
    tags,
    tagsOrder,
    imagesPending,
    imagesLoaded,
    images
  ] = useSelector(state => [
    state.jobsPending,
    state.jobsLoaded,
    state.jobs,
    state.jobsOrder,
    state.tagsPending,
    state.tagsLoaded,
    state.tags,
    state.tagsOrder,
    state.imagesPending,
    state.imagesLoaded,
    state.images
  ]);

  const entries = type === "job" ? jobs : tags;
  const entriesOrder = type === "job" ? jobsOrder : tagsOrder;

  useEffect(() => {
    if (type === "job" && !jobsPending && !jobsLoaded) {
      requestJobs(dispatch);
    }
    if (type === "tag" && !tagsPending && !tagsLoaded) {
      requestTags(dispatch);
    }
    if (!imagesPending && !imagesLoaded) {
      requestImages(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>
          {type === "job" ? "Featured Projects" : "Portfolio"} | PCI Custom
          Builders
        </title>
      </Helmet>
      {(type === "job" ? !jobsLoaded : !tagsLoaded) || !imagesLoaded ? (
        <SpinnerContainer />
      ) : (
        <>
          <Row className="text-center justify-content-center padded-row">
            <Col md={12}>
              <h2>{type === "job" ? "Featured Projects" : "Portfolio"}</h2>
            </Col>
          </Row>
          <Row className="padded-row">
            <Col className="d-flex justify-content-center">
              <div className="img-flex-grid">
                {entriesOrder.map(id => (
                  <div key={id} className="img-flex-grid-col">
                    <div
                      className="img-flex-grid-img"
                      style={
                        entries[id].cover && images[entries[id].cover]
                          ? {
                              backgroundImage:
                                "url(" +
                                images[entries[id].cover].thumbnail_url +
                                ")"
                            }
                          : entries[id].image_ids &&
                            entries[id].image_ids.length > 0 &&
                            images[entries[id].image_ids[0]]
                          ? {
                              backgroundImage:
                                "url(" +
                                images[entries[id].image_ids[0]].thumbnail_url +
                                ")"
                            }
                          : {}
                      }
                    >
                      <Link
                        to={
                          (type === "job" ? "/projects/" : "/portfolio/") + id
                        }
                      >
                        <img
                          variant="top"
                          src={
                            entries[id].cover
                              ? images[entries[id].cover].thumbnail_url
                              : ""
                          }
                          alt="No thumbnail"
                        />
                        <h4 className="img-flex-grid-title">
                          {entries[id].name}
                        </h4>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
