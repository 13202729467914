import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers.js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// CSS imports
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-select/dist/css/bootstrap-select.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/index.css";

// JS library imports
import "jquery";
import "popper.js";
import "bootstrap";
import "bootstrap-select";

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/">
      <GoogleReCaptchaProvider reCaptchaKey="6LdZZLEZAAAAAENuOcEmX0BjL7nLkR-wWsqJ8i9C">
        <App />
      </GoogleReCaptchaProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
