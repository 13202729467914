import React from "react";

import "../css/Footer.css";

export default function(props) {
  return (
    <footer className="footer mt-auto py-3">
      <div>&copy; 2020 PCI Custom Builders</div>
    </footer>
  );
}
