import { ACTIONS } from "./actions.js";

const initialState = {
  contactPending: false,
  contactLoaded: false,

  instagramPending: false,
  instagramLoaded: false,
  instagramFeed: {},

  tagsPending: false,
  tagsLoaded: false,
  tags: {},
  tagsOrder: [],

  jobsPending: false,
  jobsLoaded: false,
  jobs: {},
  jobsOrder: [],

  showcasePending: false,
  showcaseLoaded: false,
  showcase: [],

  imagesPending: false,
  imagesLoaded: false,
  images: {},

  filterTag: "",
  filterJob: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CONTACT_SEND_REQUESTED:
      return { ...state, contactPending: true };
    case ACTIONS.CONTACT_SEND_SUCCESSFUL:
      return { ...state, contactPending: false, contactLoaded: true };
    case ACTIONS.CONTACT_SEND_FAILED:
      return { ...state, contactPending: false, contactLoaded: false };
    case ACTIONS.CONTACT_SEND_RESET:
      return { ...state, contactPending: false, contactLoaded: false };

    case ACTIONS.UPDATE_FILTER_TAG:
      return { ...state, filterTag: action.value };
    case ACTIONS.UPDATE_FILTER_JOB:
      return { ...state, filterJob: action.value };

    case ACTIONS.INSTAGRAM_FEED_REQUESTED:
      return { ...state, instagramPending: true };
    case ACTIONS.INSTAGRAM_FEED_SUCCESSFUL:
      return {
        ...state,
        instagramPending: false,
        instagramLoaded: true,
        instagramFeed: action.instagramFeed
      };
    case ACTIONS.INSTAGRAM_FEED_FAILED:
      return { ...state, instagramPending: false, instagramLoaded: false };

    case ACTIONS.TAGS_REQUESTED:
      return { ...state, tagsPending: true };
    case ACTIONS.TAGS_SUCCESSFUL:
      return {
        ...state,
        tagsPending: false,
        tagsLoaded: true,
        tags: action.tags,
        tagsOrder: action.tagsOrder
      };
    case ACTIONS.TAGS_FAILED:
      return { ...state, jobsPending: false, jobsLoaded: false };

    case ACTIONS.JOBS_REQUESTED:
      return { ...state, jobsPending: true };
    case ACTIONS.JOBS_SUCCESSFUL:
      return {
        ...state,
        jobsPending: false,
        jobsLoaded: true,
        jobs: action.jobs,
        jobsOrder: action.jobsOrder
      };
    case ACTIONS.JOB_FAILED:
      return { ...state, jobsPending: false, jobsLoaded: false };

    case ACTIONS.SHOWCASE_REQUESTED:
      return { ...state, showcasePending: true };
    case ACTIONS.SHOWCASE_SUCCESSFUL:
      return {
        ...state,
        showcasePending: false,
        showcaseLoaded: true,
        showcase: action.showcase
      };
    case ACTIONS.SHOWCASE_FAILED:
      return { ...state, showcasePending: false, showcaseLoaded: false };

    case ACTIONS.IMAGES_REQUESTED:
      return { ...state, imagesPending: true };
    case ACTIONS.IMAGES_SUCCESSFUL:
      return {
        ...state,
        imagesPending: false,
        imagesLoaded: true,
        images: action.images
      };
    case ACTIONS.IMAGES_FAILED:
      return { ...state, imagesPending: false, imagesLoaded: false };

    default:
      return state;
  }
}
