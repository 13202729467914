import React from "react";
import { Switch, Route } from "react-router-dom";
import "./css/App.css";

import NavBar from "./components/NavBar.js";
import Footer from "./components/Footer.js";
import Home from "./pages/Home.js";
import NotFound from "./pages/NotFound.js";

import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import JobsTags from "./pages/JobsTags.js";
import JobTag from "./pages/JobTag.js";

export default function() {
  return (
    <div className="App">
      <NavBar />
      <div className="Page">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route
            exact
            path="/portfolio"
            component={props => <JobsTags type="tag" {...props} />}
          />
          <Route
            exact
            path="/projects"
            component={props => <JobsTags type="job" {...props} />}
          />
          <Route
            exact
            path="/portfolio/:entryId"
            component={props => <JobTag type="tag" {...props} />}
          />
          <Route
            exact
            path="/projects/:entryId"
            component={props => <JobTag type="job" {...props} />}
          />

          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
