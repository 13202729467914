import React from "react";
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "peter-lightbox";
import Gallery from "react-photo-gallery";

import "../css/Footer.css";

export default function({ filterJob, filterTag, ...props }) {
  return (
    <SimpleReactLightbox key={filterJob + "-" + filterTag}>
      <GalleryBox {...props} />
    </SimpleReactLightbox>
  );
}

function GalleryBox({
  images,
  onChange = () => {},
  targetRowHeight = 156,
  startIndex,
  endIndex,
  ...props
}) {
  const { openLightbox } = useLightbox();

  const handleClick = (event, { photo, index }) => {
    openLightbox(startIndex + index);
  };

  return (
    <>
      <SRLWrapper
        images={images.map(image => ({
          src: image.src,
          thumbnail: image.thumbnail,
          key: image.key,
          caption: image.caption
        }))}
        options={{
          settings: {
            hideControlsAfter: false,
            disablePanzoom: true
          },
          buttons: {
            showAutoplayButton: false,
            showThumbnailsButton: false,
            showDownloadButton: false
          }
        }}
        callbacks={{
          onSlideChange: object => onChange(object.index)
        }}
      ></SRLWrapper>
      <Gallery
        photos={images
          .map(image => ({
            src: image.thumbnail,
            width: image.width,
            height: image.height,
            key: image.key
          }))
          .slice(startIndex, endIndex)}
        onClick={handleClick}
        targetRowHeight={targetRowHeight}
      />
    </>
  );
}
