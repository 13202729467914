import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { useMedia } from "use-media";

import { requestShowcase } from "../redux/actions.js";
import "../css/HomeCarousel.css";

export default function(props) {
  const dispatch = useDispatch();
  const [showcasePending, showcaseLoaded, showcase] = useSelector(state => [
    state.showcasePending,
    state.showcaseLoaded,
    state.showcase
  ]);

  useEffect(() => {
    if (!showcasePending && !showcaseLoaded) {
      requestShowcase(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [interval, setInterval] = useState(5000);

  const isMobile = useMedia({ maxWidth: "768px" });

  const handlePause = () => {
    setInterval(paused ? 5000 : null);
    setPaused(!paused);
  };

  const handleSelect = (selectedIndex, e) => {
    // if (!paused)
    setIndex(selectedIndex);
  };

  return (
    <div id="home-carousel-container">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        fade={true}
        id="home-carousel"
        pause={false}
        interval={interval}
        indicators={!isMobile}
      >
        {!showcaseLoaded ? (
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-img"
              style={{ backgroundColor: "gray" }}
              src=""
              alt="Loading"
            />
          </Carousel.Item>
        ) : (
          showcase.map(imageSrc => (
            <Carousel.Item key={imageSrc}>
              <img
                className="d-block w-100 carousel-img"
                src={imageSrc}
                alt="Showcase Carousel"
              />
            </Carousel.Item>
          ))
        )}
      </Carousel>
      <button id="home-carousel-pause" onClick={handlePause}>
        <FontAwesomeIcon
          icon={paused ? faPlayCircle : faPauseCircle}
          size="6x"
          color="white"
        />
      </button>
    </div>
  );
}
