import React, { useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

export function MapContainer(props) {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };
  const onClose = props => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  return (
    <Map
      google={props.google}
      zoom={14}
      style={{
        width: "100%",
        height: "100%"
      }}
      containerStyle={{
        width: "100%",
        height: "100%",
        minHeight: "300px",
        position: "static"
      }}
      initialCenter={{
        lat: 40.706617,
        lng: -74.549328
      }}
    >
      <Marker onClick={onMarkerClick} name={"Our Location"} />
      <InfoWindow
        marker={activeMarker}
        visible={showingInfoWindow}
        onClose={onClose}
      >
        <div>{selectedPlace.name}</div>
      </InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDmdAppClnTSweaz6rCI6x1RoPSLAHjOo8"
})(MapContainer);
