import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { sendContactForm, resetContactResponse } from "../redux/actions.js";

import Map from "../components/Map.js";

export default function(props) {
  const dispatch = useDispatch();
  const responded = useSelector(state => state.contactLoaded);
  const disabled = useSelector(
    state => state.contactPending && !state.contactLoaded
  );
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const phoneNumber =
    "973".toUpperCase() +
    "-" +
    "239".toUpperCase() +
    "-" +
    "6909".toUpperCase();

  const clearForm = () => {
    setEmail("");
    setName("");
    setSubject("");
    setMessage("");
    setTimeout(() => resetContactResponse(dispatch), 4000);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    const validity = form.checkValidity();
    if (!validity) {
      // Invalid form
      return;
    }
    const token = await executeRecaptcha("something");
    sendContactForm(
      dispatch,
      { email, name, subject, message, token },
      clearForm
    );
  };
  return (
    <>
      <Helmet>
        <title>Contact Us | PCI Custom Builders</title>
      </Helmet>
      <Row
        className="text-center justify-content-center padded-row"
        style={{ paddingBottom: 0 }}
      >
        <Col md={12}>
          <h1>Contact Us</h1>
          <h4>
            <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
          </h4>
        </Col>
      </Row>
      <Row className="padded-row">
        <Col md={6}>
          <Map />
        </Col>
        <Col md={6}>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="ControlName">
              <Form.Label>Your Name (required)</Form.Label>
              <Form.Control
                type="text"
                required
                value={name}
                onChange={e => setName(e.target.value)}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Group controlId="ControlEmail">
              <Form.Label>Your Email (required)</Form.Label>
              <Form.Control
                type="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Group controlId="ControlSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Group controlId="ControlMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={message}
                onChange={e => setMessage(e.target.value)}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Text muted>
              This site is protected by reCAPTCHA, and subject to the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of service</a>.
            </Form.Text>
            <br />
            <Button type="submit" disabled={disabled}>
              Send
            </Button>
            {responded && (
              <h4
                style={{
                  color: "green",
                  display: "inline",
                  marginLeft: "15px"
                }}
              >
                Message sent!
              </h4>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
}
