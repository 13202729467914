import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import pciLogo from "../img/pcilogo.png";

import "../css/NavBar.css";

const navPages = [
  { title: "Home", path: "/" },
  { title: "Featured Projects", path: "/projects/" },
  { title: "Portfolio", path: "/portfolio/" },
  { title: "About Us", path: "/about/" },
  { title: "Contact Us", path: "/contact/" }
];

export default function(props) {
  const loc = useLocation();
  const activePath = loc.pathname + (loc.pathname.endsWith("/") ? "" : "/");
  //
  return (
    <header id="navbar-container" className="ml-auto mr-auto" role="banner">
      <div id="block-logo">
        <Link className="navbar-logo" to="/">
          <img
            alt="PCI Custom Builders"
            src={pciLogo}
            className="d-inline-block align-top navbar-logo"
          />
        </Link>
      </div>
      <Navbar expand="lg" variant="light" id="navbar">
        <Navbar.Brand as={Link} to="/" id="inline-logo">
          <img
            alt="PCI Custom Builders"
            src={pciLogo}
            className="d-inline-block align-top navbar-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-auto">
            {navPages.map(page => (
              <Nav.Link
                as={Link}
                to={page.path}
                key={page.path}
                className={page.path === activePath ? "active" : ""}
              >
                {page.title}
              </Nav.Link>
            ))}
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/pcicustombuilders/?hl=en"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" /> Instagram
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}
