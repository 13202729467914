import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import oldTruck from "../img/oldtruck.png";
import aboutFamily1 from "../img/about-family-1.jpg";
import aboutFamily2 from "../img/about-family-2.jpg";

import "../css/About.css";

export default function () {
  return (
    <>
      <Helmet>
        <title>About | PCI Custom Builders</title>
      </Helmet>
      <Row className="text-center justify-content-center padded-row home-about-row">
        <Col md={12}>
          <h2>About Our Company</h2>
        </Col>
      </Row>
      <Row className="padded-row extra-padded-row">
        <Col>
          <div className="clearfix">
            <img
              src={oldTruck}
              alt="Old Truck"
              className="col-md-6 float-md-end mb-3 ms-md-3"
            />
            <p>
              PCI Custom Builders, located in Basking Ridge, NJ, has been a
              family-owned business for over 80 years. Our business history is
              at the root of our commitment. Each completed project reflects a
              family legacy of craftsmanship, value and pride. A history of
              skill and quality passed down over generations; it is what
              separates PCI from the rest. Our work includes new constructions,
              alterations, additions, home renovations and fine woodworking.
            </p>
            <p>
              We develop a close relationship with our clients, starting at the
              very beginning of the design phase. This rapport ensures the
              satisfaction of our customers. We pride ourselves in providing
              excellent service through superior craftsmanship and attention to
              detail. We use quality materials and customize each project to the
              client’s specifications.
            </p>
            <p>
              We are passionate about our work. This dedication is what allows
              us to repeatedly deliver a level of performance above and beyond
              our client’s expectations.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="padded-row extra-padded-row">
        <Col md={6}>
          <img
            src={aboutFamily2}
            alt=""
            className="img-float-right bottom-img"
          />
        </Col>
        <Col md={6}>
          <img
            src={aboutFamily1}
            alt=""
            className="img-float-left bottom-img"
          />
        </Col>
      </Row>
    </>
  );
}
