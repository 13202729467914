import { useState, useEffect, useCallback } from "react";

import $ from "jquery";

const useIsMobile = function (maxWidth) {
  const width = maxWidth || 768;
  const getIsMobile = useCallback(
    function () {
      return $(window).width() < width;
    },
    [width]
  );

  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    $(window).on("resize", () => {
      setIsMobile(getIsMobile());
    });

    return () => $(window).off("resize");
  }, [getIsMobile]);

  return isMobile;
};

export default useIsMobile;
