import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function(props) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <FontAwesomeIcon icon={faSpinner} color="gray" size="2x" spin />
    </div>
  );
}
